import $ from 'jquery';

// DataTables - packages installed with Yarn
import 'datatables.net-bs4'
import 'datatables.net-fixedheader-bs4';
import 'datatables.net-rowreorder-bs4';

$( document ).ready(function() {
	$('#datatable_simple').DataTable({
  	fixedHeader: true
	});

	$('#datatable_reorderable').DataTable({
  	fixedHeader: true,
  	rowReorder: true
	});

  $('#datatable_date_desc').DataTable({
    fixedHeader: true,
    order: [
      [ 0, 'desc' ],
      [ 1, 'asc' ],
      [ 2, 'asc' ]
    ]
  });

  $('#datatable_users').DataTable({
    fixedHeader: true,
    order: [
      [ 2, 'asc' ], 
      [ 3, 'asc' ]
    ]
  });

  $('#datatable_manage_nominees').DataTable({
    fixedHeader: true,
    order: [
      [ 0, 'asc' ], 
      [ 2, 'desc' ]
    ]
  });

  $('#datatable_twilio_history').DataTable({
    fixedHeader: true,
    order: [
      [ 0, 'desc' ],
      [ 1, 'desc' ],
      [ 2, 'asc' ]
    ]
  });

});
