import $ from 'jquery';
import { ajax } from '@rails/ujs';

var num_sms_message_checks = 0;
function poll_for_sms_message_changes() {
  var sms_message_id = $('.sms_message_container').data('sms-message-id');

  if(sms_message_id) {
    ajax({
      type: 'GET',
      url: '/admin/sms_messages/'+sms_message_id+'',
      dataType: 'script',
    });

    // A person could leave the page up in their browser for a long time
    // If so, increase the timeout value to alleviate network and database traffic
    num_sms_message_checks++;
    var timeout_val = 1000;

    if(num_sms_message_checks > 1800) {
      timeout_val = 5000;
    }

    setTimeout(poll_for_sms_message_changes, timeout_val);
  }
}

$(() => {
  poll_for_sms_message_changes();
});
