import $ from 'jquery';

$(document).ready(function() {
  $(".toggle_content").hide();
  $(".fa-angle-down").hide();
    
  $(".toggle_heading").click(function(event){
    event.preventDefault();
    $(this).closest(".toggle_container").find(".fa-angle-right").toggle();
    $(this).closest(".toggle_container").find(".fa-angle-down").toggle();
    $(this).closest(".toggle_container").find(".toggle_content").slideToggle("fast");
  });
  
  $(".toggle_link").click(function(event){
    event.preventDefault();
    $(this).closest(".toggle_container").find(".fa-angle-right").toggle();
    $(this).closest(".toggle_container").find(".fa-angle-down").toggle();
    $(this).closest(".toggle_container").find(".toggle_content").slideToggle("fast");
  });

});
