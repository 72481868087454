import $ from 'jquery';

$(document).ready(function() {

  $(".dynamic_fields_container").on('click', '.add_field', function(event) {
    event.preventDefault();
    var dynamic_fields_container = $(this).closest('.dynamic_fields_container');
    var base_input_html = $(dynamic_fields_container).find('.base_input_html').html();
    $('.dynamic_fields_container .inputs_container').append(base_input_html);
  });

  $(".dynamic_fields_container").on('click', '.remove_field', function(event) {
    event.preventDefault();
    $(this).closest('.input_wrapper').find("input").val("");
    $(this).closest('.input_wrapper').hide();
  });

});
