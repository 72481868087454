// Complete SortableJS (with all plugins)
import Sortable from 'sortablejs/modular/sortable.complete.esm.js';

$( document ).ready(function() {
	var sortablejs_list = document.getElementById('sortablejs-container');

	if(sortablejs_list != null) {

		Sortable.create(sortablejs_list, {
		    dataIdAttr: 'data-id',
	    	store: null,  // @see Store
	    	filter: '.disabled', // 'disabled' class is not draggable
		    ghostClass: "sortable-ghost",  // Class name for the drop placeholder
		    chosenClass: "sortable-chosen",  // Class name for the chosen item
		    dragClass: "sortable-drag",  // Class name for the dragging item

		    setData: function (/** DataTransfer */dataTransfer, /** HTMLElement*/dragEl) {
		        dataTransfer.setData('Text', dragEl.textContent); // `dataTransfer` object of HTML5 DragEvent
		        // console.log(textContent);
		    },

		    // Called when dragging element changes position
		    onSort: function (event) {
		        // same properties as onEnd
		        event.newIndex // most likely why this event is used is to get the dragging element's current index

		        var items = event.to.children;
		    	var sort_order = [];
				for (var i = 0; i < items.length; i++) { 
					sort_order.push($(items[i]).data('id')); 
				} 

				console.log(sort_order); 
				var sort_order_json = JSON.stringify(sort_order);
				console.log(sort_order_json); 

				var target_url = window.location.href + "/update_sort_order";

				$.ajax({
				  url: target_url,
				  type: "post", 
				  data: "data=" + sort_order,
			      dataType: 'script',
				  success: function(data, textStatus, jqXHR){
				  	// alert("Done, textStatus: " + textStatus);
				  },
				  error: function(jqXHR, textStatus, errorThrown){
				  	alert("Error: " + errorThrown);
				  }
				})

		    },
		    animation: 150
		});
	}
});
