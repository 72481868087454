import $ from 'jquery';

$(document).ready(function () {
  const $breadcrumb = $('.c-breadcrumb');
  const $breadcrumbItems = $breadcrumb.find('li');

  // if any breadcrumb items contain a link, show it.
  if ($breadcrumbItems.length >= 1 && $breadcrumbItems.find('a').length) {
    $breadcrumb.removeClass('d-none');
  }
});
