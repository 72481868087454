import $ from 'jquery';

$(document).ready(function () {
  // Generic: Reposition placeholder if input has value
  $('input.o-form__input').on('input', function () {
    var $field = $(this).closest('.o-form__field');
    $field[this.value ? 'addClass' : 'removeClass']('has-value');
  }).trigger('input');

  const $signinForm = $('.c-signin').find('form');
  if ($signinForm.length) {
    $signinForm.find('input.o-form__input').on('input', function () {
      $signinForm.find('input:submit').prop('disabled', Boolean(!$signinForm[0].checkValidity()));
    }).trigger('input');
  }

  var $careerForm = $('.c-career-form');
  if ($careerForm.length) {
    $careerForm.find('input.o-form__input').on('input', function () {
      $careerForm.find('input:submit').prop('disabled', Boolean(!$careerForm[0].checkValidity()));
    }).trigger('input');
  }
});
