import { Controller } from "stimulus"
import { ajax } from '@rails/ujs';

export default class extends Controller {
  static targets = [ "select" ]

  filter(){
    $('#projects-loading-overlay').removeClass('d-none');

    ajax({
      type: 'GET',
      url: '/projects',
      data: new URLSearchParams({ department: this.selectTarget.value }).toString(),
      dataType: 'script'
    });
  }
}
