$(document).ready(function () {
  // Close modal by clicking on the dark opaque overlay
  $('body').on('click', '.js-modal--employee', function(e) {
    if ($(e.target).hasClass('js-modal--employee')) {
      var $modal = $(this);
      $modal.fadeOut('fast', function() {
        $modal.removeClass('active').remove();
      });
    }
  });

  // Close modal by clicking on the close button
  $('body').on('click', '.c-modal--close', function() {
    var $modal = $('body').find('.js-modal--employee');
    $modal.trigger('click');
  });

  // Close modal by hitting the <esc> key
  $(document).on('keyup', function (evt) {
    if (evt.keyCode == 27) {
      var $modal = $('body').find('.js-modal--employee');
      if ($modal.is(':visible')) { $modal.trigger('click'); }
    }
  });
});
