import $ from 'jquery';

$( document ).ready(function() {

  $( '.highlight_table_rows tr' ).each(function() {
    var checkbox_is_checked = $(this).find("input[type=checkbox]").is(":checked");
    var parent = $(this).closest("tr");
    update_highlight(checkbox_is_checked, parent)
  });

  $( '.highlight_table_rows' ).on("change", "input[type=checkbox]", function(event) {
    var checkbox_is_checked = $(this).is(":checked");
    var parent = $(this).closest("tr");
    update_highlight(checkbox_is_checked, parent)
  });

});

function update_highlight(val, parent) {
  if(val) {
    add_highlight(parent)
  } else {
    remove_highlight(parent)
  }
}

function add_highlight(elem) {
  $(elem).css( "background-color", "rgba(255,246,143,1)" );
}

function remove_highlight(elem) {
  $(elem).css( "background-color", "transparent" );
}
