import $ from 'jquery';

$(document).ready(function() {
  var $mobileMenuBtn = $('.c-nav-mobile--menu-btn');
  var $mobileMenu = $('.c-nav-mobile--menu');
  if (!$mobileMenuBtn.length) return;

  $mobileMenuBtn.on('click', function() {
    $mobileMenuBtn.toggleClass('is-active');
    $mobileMenu.toggleClass('is-active');
  });
});
