// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");
require("jquery");
require("bootstrap/dist/js/bootstrap");
import "@fortawesome/fontawesome-free/js/all";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

var Trix = require("trix");
require("@rails/actiontext");

Trix.config.blockAttributes.heading1.tagName = "h3";

import "controllers";

// Load all files in the app/javascript/src/ folder
const files = require.context('../src', true, /\.js$/);
files.keys().forEach(files);
